.NavBar.fixxed {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  right: 0;
  left: 0;
  z-index: 100;
  width: 100%;
}
a,
a:hover {
  color: black !important;
  text-decoration: none;
}
.container {
  position: relative;
  min-height: 100vh;
}
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}
.row {
  margin: 0px !important;
  padding: 0px !important;
}
.PadBottom {
  padding-bottom: 30px !important;
}
.maxWidth {
  max-width: 1280px !important;
  width: 100%;
  margin: 0 auto;
  display: block !important;
}
.White {
  color: white !important;
}
.Black {
  color: black !important;
}
.Bold {
  font-weight: 600;
}
h1,
h2,
h3 {
  padding-top: 30px;
  padding-bottom: 30px;
  cursor: context-menu;
}
p {
  cursor: context-menu;
}
.al {
  border-style: none !important;
}
.ar {
  border-style: none !important;
}
.button {
  border-radius: 10px;
  width: 201px;
  height: 48px;
  color: #ea9223 !important;
  font-weight: bolder;
}
.butt {
  border-radius: 10px;
  width: 100%;
  color: white;
  background-color: #ea9223;
  border: none;
  height: 44px;
}
.BackgroundImage {
  background-size: cover;
  position: relative;
  text-align: center;
}
.searchInput:focus,
.focusNone:focus {
  outline: none;
}
.ant-rate-star-full path {
  color: red !important;
}
.ant-rate-star-zero path {
  color: grey !important;
}
.MuiBox-root-3 {
  margin-bottom: 0 !important;
}
.ft {
  text-align: center;
  background-color: #f3f2f2 !important;
}
.footer {
  font-style: none;
  color: black !important;
}
.searchbar {
  background-color: white;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  padding: 10px !important;
}
.email {
  width: 100% !important;
  height: 44px !important;
}
.coverimg {
  min-height: 323px;
}
.dropdownbtn {
  min-height: 39.32px !important;
  border-top-right-radius: 57px !important;
  border-bottom-right-radius: 50px !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.searchbox {
  background-color: white;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  min-height: 65px;
}

.textarea {
  resize: none;
  overflow: auto;
}
.headerimage {
  background-size: cover;
  position: relative;
  text-align: center;
  min-height: 610px;
}
.img-border {
  border-radius: 30px !important;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  padding: 10px;
  overflow: hidden;
}
.searchbutton {
  min-height: 30px !important;
  width: 60px !important;
  float: right;
}
.dropdownmenu {
  border-radius: 15px !important;
}
.searchbox:focus {
  border-color: transparent !important;
  box-shadow: 0px 0px 0px red !important;
}
.SearchButon {
  font-size: 18px;
  font-weight: 600;
  padding: 0 27px;
  margin-right: 1px;
  height: 50px;
}
.btn.focus,
.btn:focus {
  box-shadow: 0px 0px 0px 0px !important;
}
.aboutusimage {
  background-size: cover;
  position: relative;
  text-align: center;
  height: 323px !important;
  width: inherit !important;
}
.newsimage {
  width: 315px;
}
.formtextarea {
  resize: none !important;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}
.formtextarea:focus {
  box-shadow: 0px 0px 0px red !important;
}
.submitbtn {
  border-radius: 10px;
  width: 100%;
  color: white;
  background-color: #ea9223;
  border: none;
  height: 44px;
}
.fnameborder {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.lnameborder {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.textareaborder {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.form {
  background-color: #f9f9f9 !important;
}
.phone {
  border-left-style: none !important;
  border-radius: 0% !important;
}
.contact {
  margin-top: -100px !important;
}
.aboutus2image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 614px !important;
}
.notifications {
  font-size: 14px !important;
}
.rightRadio .ant-radio {
  float: right;
}
@media all and (min-width: 175px) and (max-width: 375px) {
  .accounts {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .DisplayNoneonSmall {
    display: none;
  }
  .uAccount {
    font-size: 16px !important;
  }
  .earn {
    margin-top: -100px !important;
  }
  .lnameborder {
    border-radius: 0px !important;
  }
  .saloonimg {
    width: 38% !important;
  }
  .ant-rate {
    font-size: 10px !important;
  }
  h1 {
    font-size: 18px !important;
  }
  p {
    font-size: 10px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px !important;
  }
}

@media all and (min-width: 375px) and (max-width: 667px) {
  .accounts {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .DisplayNoneonSmall {
    display: none;
  }
  .uAccount {
    font-size: 18px !important;
  }
  .ant-rate {
    font-size: 10px !important;
  }
  .earn {
    margin-top: -100px !important;
  }
  .lnameborder {
    border-radius: 0px !important;
  }
  .saloonimg {
    width: 47% !important;
  }
  h1 {
    font-size: 27px !important;
  }
  p {
    font-size: 14px !important;
  }
  h2 {
    font-size: 23px !important;
  }
  h3 {
    font-size: 20px !important;
  }
}

@media all and (min-width: 667px) and (max-width: 735px) {
  .saloonimg {
    width: 43% !important;
  }
  .DisplayNoneonSmall {
    display: none;
  }
  .accounts {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uAccount {
    font-size: 20px !important;
  }
  .ant-rate {
    font-size: 10px !important;
  }
  .earn {
    margin-top: -150px !important;
  }
  .lnameborder {
    border-radius: 0px !important;
  }
  h1 {
    font-size: 25px !important;
  }
  p {
    font-size: 14px !important;
  }
  h2 {
    font-size: 20px !important;
  }
  h3 {
    font-size: 18px !important;
  }
}

@media all and (min-width: 736px) and (max-width: 767px) {
  .saloonimg {
    width: 43% !important;
  }
  .DisplayNoneonSmall {
    display: none;
  }
  .accounts {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uAccount {
    font-size: 22px !important;
  }
  .earn {
    margin-top: -200px !important;
  }
  .lnameborder {
    border-radius: 0px !important;
  }
}

@media all and (min-width: 767px) and (max-width: 968px) {
  .saloonimg {
    width: 43% !important;
  }
  .nav-link.Black.active {
    font-size: 13px !important;
  }
  .nav-item {
    font-size: 13px !important;
  }
  .PersonalInfo {
    margin-left: auto !important;
  }
  .uAccount {
    font-size: 13px !important;
  }
  .earn {
    margin-top: -200px !important;
  }
  .lnameborder {
    border-radius: 0px !important;
  }
  .nav-link.Black.active {
    font-size: 14px !important;
  }
}

@media all and (min-width: 968px) and (max-width: 1040px) {
  .mapHeight {
    height: auto;
    position: relative;
  }
  .saloonimg {
    width: 43% !important;
  }
  .PersonalInfo {
    margin-left: auto !important;
  }
  .uAccount {
    font-size: 20px !important;
  }
  .ant-rate {
    font-size: 9px !important;
  }
  .earn {
    margin-top: -250px !important;
  }
}

@media all and (min-width: 1040px) {
  .mapHeight {
    height: auto;
    position: relative;
  }
  .PersonalInfo {
    margin-left: auto !important;
  }
  .uAccount {
    font-size: 22px !important;
  }
  .saloonimg {
    width: 43% !important;
  }
  .earn {
    margin-top: -430px !important;
  }
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  background-color: #ea9223 !important;
  color: white !important;
}
.anticon.anticon-star {
  color: red !important;
  font-size: 20px !important;
}
.anticon.anticon-safety-certificate {
  color: red !important;
  font-size: 25px !important;
}
.loginemail {
  height: 50.57px !important;
  border-color: #e5e5e5 !important;
}
.loginpassword {
  height: 50.57px !important;
  border-color: #e5e5e5 !important;
}
.loginform {
  margin-top: -150px !important;
}
.fillcolor {
  background-color: #f9f9f9 !important;
}
.logingoogle {
  height: 50.57px !important;
  border-style: none !important;
}
.google {
  border: 1px solid !important;
  border-color: #ececec !important;
}
.googlebtn {
  height: 30px !important;
  width: 25px !important;
  float: left !important;
}
.signupbtn {
  width: 100%;
  color: white;
  background-color: #ea9223;
  border: none;
  height: 44px;
}
.Red {
  color: #9e0b0f;
}
.strike h6 {
  color: rgb(156, 156, 156);
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  line-height: 0.1em;
}
.strike h6 span {
  background: #fff;
}
.viewbtn {
  border-radius: 10px;
  width: 100%;
  color: white;
  background-color: #ffc377;
  border: none;
  height: 44px;
}
.profilebtn {
  height: 44px !important;
  width: 100%;
  border-radius: 10px !important;
}
.emailborder {
  border-radius: 0% !important;
}
.addressL1border {
  border-radius: 0% !important;
  border-bottom-left-radius: 10px !important;
}
.ant-upload.ant-upload-select-picture-card {
  border: 1px solid !important;
  border-radius: 10px !important;
  border-color: #e5e5e5 !important;
}
.icon {
  background-color: #ea9223 !important;
  border-radius: 50% !important;
  color: white !important;
}
.savebtn {
  border-radius: 10px;
  width: 76px !important;
  color: white;
  background-color: #ea9223;
  border: none;
  height: 44px;
  float: right !important;
}
.mapbtn {
  border-radius: 14.5px !important;
  height: 29px !important;
  border: 1px solid !important;
  border-color: #e1e1e1 !important;
}
.mapimg {
  width: inherit !important;
}
.mapviewbtn {
  border-radius: 14.5px !important;
  height: 29px !important;
  color: white !important;
  background-color: #313131 !important;
}
.font10 {
  font-size: 10px !important;
}
.font13 {
  font-size: 13px !important;
}
.font14 {
  font-size: 14px !important;
}
.font16,
.font19to11 {
  font-size: 16px !important;
}
.font17 {
  font-size: 17px !important;
}
.font19 {
  font-size: 19px !important;
}
.font20 {
  font-size: 20px !important;
}
.font22 {
  font-size: 22px !important;
}
.font24 {
  font-size: 24px !important;
}
.font25 {
  font-size: 25px !important;
}
.font29 {
  font-size: 29px !important;
}
.font32 {
  font-size: 32px !important;
}
.font18 {
  font-size: 18px !important;
}
.font34 {
  font-size: 34px !important;
}
.font39 {
  font-size: 39px;
}
.font58 {
  font-size: 58px;
}
.font44 {
  font-size: 44px;
}
.lightGrey {
  color: rgba(255, 255, 255, 0.65);
}
.businessimg {
  max-width: 1280px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}
.ProfileArea {
  resize: none !important;
}
.bordercolor {
  border: 1px solid #e5e5e5 !important;
}
.Radius_10 {
  border-radius: 10px !important;
}
.Radius_14 {
  border-radius: 14.5px !important;
}
.Radius_16 {
  border-radius: 16px !important;
}
.Radius_7 {
  border-radius: 7px !important;
}
.Radius_20 {
  border-radius: 20px !important;
}
.bdrBotom {
  border-bottom: 1px solid rgb(0, 0, 0, 0.25);
}
.ant-spin-spinning,
.Center {
  position: sticky !important;
  height: 75%;
  top: 50%;
  left: 50%;
}
.ant-spin-dot-item {
  background-color: #ea9223 !important;
}
.Radius_top {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.darkGrey {
  border: 2px solid #858585 !important;
}
.height65 {
  height: 65px !important;
}
.height48 {
  height: 48px !important;
}
.height55 {
  height: 55px !important;
}
.height44 {
  height: 44px !important;
}
.width163 {
  width: 163px !important;
}
.width85 {
  width: 85px !important;
}
.Orange,
th {
  background-color: #ea9223 !important;
}
.Orange:hover,
th {
  background-color: #f0ac56 !important;
}
th span,
th {
  color: white !important;
}
tbody td {
  vertical-align: middle !important;
}
.ant-table-cell button {
  min-width: 94px;
}
.Grey {
  color: #c4c4c4 !important;
}
.DarkBlack {
  background-color: #313131 !important;
}
.border196 {
  border-bottom-right-radius: 196px !important;
}
.ant-tabs-ink-bar {
  background-color: #ea9223 !important;
}
.ant-tabs-tab-btn {
  color: black !important;
}
.orangeborder {
  border: 1px solid #ea9223 !important;
  margin: 5px !important;
}
.orangecolor {
  color: #ea9223 !important;
  font-size: 12px !important;
}
.card {
  border: none !important;
}
.NavBar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.navbar-brand img {
  width: 70%;
}
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid !important;
  color: #c0c0c0 !important;
  flex: 1 !important;
  padding-top: 1rem !important;
}
.checkout-steps > div.active {
  border-top: 0.3rem #f08000 solid !important;
  color: #f08000 !important;
}
.ant-switch-checked {
  background-color: #ea9223 !important;
}
.account:hover p,
.account:hover .uAccount,
.account:hover h3 a {
  color: white !important;
}
.account:hover {
  background-color: #ea9223 !important;
}
.modal-content {
  border-radius: 15px !important;
}
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: black !important;
}
.btnImg {
  position: relative;
}
.btnImg .btn {
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}
.crossimg {
  position: relative;
}
.crossimg .btn {
  position: inherit;
  top: 10%;
  left: 5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #ea9223 !important;
}
.form-control,
.font14 {
  font-size: 14px !important;
}
.dropdown-menu.dropdownmenu.show {
  padding: 10px !important;
  width: 200px !important;
}
.dropdown-menu {
  top: 50% !important;
  margin: 1.125rem 0 0 !important;
}
.borderleft {
  border-left: 1px solid #d5d5d5 !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
}
@media only screen and (max-width: 767px) {
  .D_view {
    display: none;
  }
  .navbar-brand img {
    float: left;
  }
  .mask .font58 {
    font-size: 32px !important;
  }
  .navbar-collapse ul {
    background-color: white;
    padding: 35px 10px;
    border-radius: 4px;
  }
  .navbar-collapse ul li {
    background-color: #fbfcff;
    margin-bottom: 5px;
    padding: 5px 0px;
  }
  .navbar-collapse.collapse.show ul li a {
    color: black !important;
    font-size: 16px;
    text-align: left;
    padding-left: 16px;
  }
  .navbar-dark .navbar-toggler {
    background-color: transparent !important;
  }
  .bnav button.navbar-toggler {
    background-color: transparent !important;
  }
  .m_view {
    display: unset;
  }
  .btnImg .btn {
    font-size: 12px;
    top: -3px;
    border-radius: 10.5px;
  }
  .SearchButon {
    margin-right: auto;
    margin-left: 16px;
  }
  .searchbox {
    background-color: transparent;
  }
  .searchInput {
    min-height: 60px;
  }
  .font44 {
    font-size: 38px !important;
  }
  .font24 {
    font-size: 20px;
  }
  .padding_5 {
    padding: 1rem !important;
  }
}
@media only screen and (max-width: 420px) {
  .font19to11 {
    font-size: 12px !important;
  }
}
@media only screen and (min-width: 767px) {
  .m_view {
    display: none;
  }
  .D_view {
    display: unset;
  }
  .navbar-brand img {
    float: center;
  }
  .SearchButon {
    float: right;
  }
  .listingItem {
    max-height: 220px;
    height: 220px;
    min-height: 220px;
  }
}
.listingItem_img {
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
}
.listingItem .thumbNail {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  max-width: 100%;
}
.Align_middle {
  display: flex;
  align-items: center;
}
.dropdown-item.active,
.dropdown-item:active {
  color: black !important;
  background-color: white !important;
}
.m_view .dropdown-item {
  color: white !important;
}
.navbar-toggler {
  color: black !important;
  background-color: black !important;
}
.paddingTop_3 {
  padding-top: 1rem !important;
}
.paddingTop_5 {
  padding-top: 3rem !important;
}
.padding_5 {
  padding: 3rem !important;
}
.footerFixed {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}
.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
  display: none !important;
}
.ant-upload.ant-upload-select.ant-upload-select-picture {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}
.paddingAll {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-right: 0px !important;
  padding-left: 15px !important;
}
.mxAuto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.ant-upload-list-picture-container {
  display: none !important;
}
.ant-upload-list-item-error {
  border: 1px solid #d9d9d9 !important;
}
.ant-tooltip.ant-tooltip-placement-top {
  display: none;
}
.verticalScroll {
  width: 100%;
  overflow-x: scroll;
}
.scroll {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: -1px 0px 16px grey;
  padding: 15px;
  word-wrap: break-word;
}
.mapscroll {
  position: sticky;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;
}
::-webkit-scrollbar {
  width: 10px;
}
.verticalScroll::-webkit-scrollbar {
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
.backgroundGrey {
  background-color: rgb(240, 242, 245);
}
.scroll,
::-webkit-scrollbar-track {
  background: white;
}
.mapscroll,
::-webkit-scrollbar-track {
  background: white;
  border-radius: none;
}
.scroll ::-webkit-scrollbar-thumb,
.mapscroll::-webkit-scrollbar-thumb,
.verticalScroll::-webkit-scrollbar-thumb {
  background: #ea9223;
  border-radius: 10px;
}
input:focus {
  outline: none !important;
  box-shadow: none;
}
.maps {
  height: 85vh;
}
.gm-style-iw.gm-style-iw-c {
  max-width: 300px !important;
  padding: 0px;
}
.gm-style-iw-t {
  padding: 0;
}
.gm-style-iw-d {
  padding: 0px;
  width: 250px;
  overflow: unset !important;
}
.gm-style .gm-style-iw-t::after {
  background: orange;
  z-index: -99;
}
.infoWindow .inff::before {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  display: block;
  background: linear-gradient(
    to top,
    rgba(35, 35, 37, 0.85) 0%,
    rgba(35, 35, 37, 0.4) 35%,
    rgba(22, 22, 23, 0) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(35, 35, 37, 0.2);
}
.gm-ui-hover-effect img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.infoWindow p {
  position: absolute;
  right: 0;
  left: 0;
  color: white;
}
.infoWindow .title {
  bottom: 25px;
}
.infoWindow .address {
  bottom: 10px;
  color: #ffffffb3;
}
.greyColor {
  color: #777;
}
.card-body {
  min-height: 230px !important;
}
.minHeight {
  min-height: 10px;
}
.RadioToButton {
  list-style-type: none;
}
.RadioToButton input {
  display: none;
}
.RadioToButton label {
  border: none;
  border-radius: 50px;
  width: 100%;
  text-align: center;
}
.RadioToButton input:checked + label,
.Checked + label {
  background: #ea9223;
  color: white;
}
.Tags label {
  border: 1px solid #ea9223;
  border-radius: 10px;
  background-color: rgb(240, 242, 245);
}
.remove {
  border: 0px;
  color: white;
  font-size: x-small;
  background: #ea9223;
  font-weight: 900;
}
.OptPopUp {
  width: fit-content !important;
}
.OptPopUp .ant-modal-content {
  padding: 16px;
  width: fit-content;
}
.OptPopUp input {
  width: 2rem !important;
  border: 1px solid rgb(0, 0, 0, 0.25);
}
.add-three-dots {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-modal-content {
  border-radius: 20px !important;
}
.combineButton {
  border-radius: 50px;
  background-color: white;
}

.RadioToButton {
  list-style-type: none;
}

.RadioToButton input {
  display: none;
}

.RadioToButton label {
  border: none;
  border-radius: 50px;
  width: 100%;
}

.RadioToButton input:checked + label,
.Checked + label {
  background: #ea9223;
  color: white;
}
.checkeddd {
  background: #ea9223;
  color: white;
  border-radius: 50px;
}
.cutomhover:hover {
  background: #ea9223;
  color: white !important;
}
.location-search-input {
  margin-bottom: 30px;
}

.autocomplete-dropdown-container {
  position: "absolute";
  z-index: 1000;
}
.location-search-input,
.location-search-input:focus,
.location-search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.autocomplete-dropdown-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1000;
  border-radius: 0 0 2px 2px;
}

.scrolll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrolll::-webkit-scrollbar {
  display: none;
}
.dropdownbtn:first-child:active {
  border-color: transparent !important;
}

.reactPhone {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
}
.form-control:focus {
  box-shadow: none !important;
}

.pac-target-input {
  margin-bottom: 0 !important;
  margin-top: 12px !important;
  height: 40px !important;
  padding-left: 0.5rem !important;
  border: 1px solid #e5e5e5 !important;
  background-color: #f9f9f9 !important;
}

.mapHeight > div > div {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
}

.phone > div > div {
  display: none;
}

.phone > div > input {
  background-color: #f9f9f9 !important;
}
.cursor {
  cursor: pointer;
}
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.button-calender {
  background-color: #f0ac56;
  color: white;
  border: none;
}
.calender-date {
  padding: 10px;
  background-color: #dde4e6;
  /* cursor: pointer; */
}
.calender-date-not-hover {
  padding: 10px;
  background-color: #f7ded7;
  /* cursor: pointer; */
}
.calender-date:hover {
  background-color: #f1ac56;
}
.calender-disable {
  cursor: not-allowed;
}
.ant-carousel .slick-dots li {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.8);
}

@media screen and (max-width: 580px) {
  .display {
    flex-direction: column;
  }
}

.top {
  top: -9.4rem !important;
}

@media screen and (max-width: 580px) {
  .top {
    top: -12rem !important;
  }
}
