.position-absolute {
    position: absolute;
    z-index: 10;
    background-color: #8191A2;
    width: 300px;
    border-radius: 4px;
  }
  
  .position-relative .dropdown-pointer {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #8191A2; /* Same background color as the dropdown */
    top: -10px; /* Adjust this based on dropdown positioning */
    left: 50%;
    transform: translateX(-50%);
  }
  
  @media (max-width: 768px) {
    /* On smaller screens (like mobile), center the dropdown under the clicked date */
    .position-absolute {
      top: calc(100% + 10px); /* Adjust this for positioning */
      left: 50%;
      transform: translateX(-50%);
      width: 90%; /* Adjust dropdown width for mobile */
      max-width: 300px;
    }
    
    .dropdown-pointer {
      top: -10px;
    }
  }
  